import React from 'react'
import {graphql} from 'gatsby'
import {mapEdgesToNodes} from '../lib/helpers'

import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Footer from '../components/footer'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Carousel from '../components/carousel'

import formheader from '../images/formheader.png'


export const query = graphql`
  query FormPageQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
      description
      logo {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
        alt
      }
      keywords
    }
  }
`

const SurveyPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site

  const heroNodes = (data || {}).heroes
    ? mapEdgesToNodes(data.heroes)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const headerStyles = {
    maxWidth: '1440px',
    width: '100%',
    margin: 'auto 0'
  }

  return (
    <Layout siteLogo={site.logo}>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Container max={1440} padding={'0px'}>
        <img id={'caro'} src={formheader} style={headerStyles}/>
      </Container>
      <Container>
        <iframe 
          src="https://docs.google.com/forms/d/e/1FAIpQLSdHzjS1Dkd8TewvDyKHAtEik2E5s8oZI36PzfSLRY1Lw469NA/viewform?embedded=true" 
          width="100%" 
          height="5000px" 
          scrolling="no"
          frameBorder="0" 
          marginHeight="0" 
          marginWidth="0">
            Loading…
        </iframe>
        <Footer />
      </Container>
    </Layout>
  )
}

export default SurveyPage